import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const localstorageKey = 'settings'
export const saveState = (state: SettingsState) => localStorage.setItem(localstorageKey, JSON.stringify(state))
export const localState: SettingsState | null = JSON.parse(localStorage.getItem(localstorageKey) ?? 'null')

export type SettingsState = {
    /** 表示モード */
    displayMode: 'light' | 'dark' | 'system',
    /** ダークモードか */
    isDarkMode: boolean,
    /** フォントサイズ */
    fontSize: 'sm' | 'md' | 'lg',
    /** 時間を表示するかどうか */
    showPeriods: 'on' | 'auto' | 'off',
    /** デバッグモード */
    debug: boolean,
}

const initialState: SettingsState = {
    displayMode: 'system',
    isDarkMode: Boolean(window.matchMedia('(prefers-color-scheme: dark)').matches),
    fontSize: 'md',
    debug: false,
    showPeriods: 'auto',
    ...localState
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setDisplayMode: (state, action: PayloadAction<SettingsState['displayMode']>) => {
            state.displayMode = action.payload;
            state.isDarkMode = (action.payload === 'system') ? Boolean(window.matchMedia('(prefers-color-scheme: dark)').matches) : (action.payload === 'dark')
            saveState(state)
        },
        setFontSize: (state, action: PayloadAction<SettingsState['fontSize']>) => {
            state.fontSize = action.payload
            saveState(state)
        },
        setShowPeriods: (state, action: PayloadAction<SettingsState['showPeriods']>) => {
            state.showPeriods = action.payload
            saveState(state)
        },
        setDebug: (state, action: PayloadAction<SettingsState['debug']>) => {
            state.debug = action.payload
            saveState(state)
        },
    },
});

export const { setDisplayMode, setFontSize, setShowPeriods, setDebug } = settingsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
/** Calendarのstateを選択 */
export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;