/** Google Fonts */
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
/** React */
import React from "react";
import ReactDOM from "react-dom/client";
/** React Redux */
import { Provider } from "react-redux";
import { store } from "src/app/store";
/** React Router Dom */
import { createBrowserRouter, RouterProvider } from "react-router-dom";
/** React Cookie */
import { CookiesProvider } from "react-cookie";
/** Other */
import App from "./App";
import Detail from "./features/detail/Detail";
import "./index.css";
/** PWA */
import ChakraProvider from "src/components/ChakraProvider";
import ErrorPage from "src/ErrorPage";
import Settings from "src/features/settings/Settings";
import MuiProvider from "src/components/MuiProvider";
import Landing from "src/pages";
import Login from "src/pages/login";
import { TimeTable } from "src/pages/timetable";
import reportWebVitals from "src/reportWebVitals";
import * as serviceWorkerRegistration from "src/serviceWorkerRegistration";
import MuiSearch from "src/features/search/Search"
import { Search } from "src/pages/search";
import Calendar from "./features/calendar/Calendar";

export const RouterPath = {
  landing: "/",
  login: "/login",
  settings: "/settings",
  calendar: "/calendar",
  search: "/search",
  detail: "/detail/:id",
};

const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    element: (
      <ChakraProvider>
        <App />
      </ChakraProvider>
    ),
    children: [
      {
        path: RouterPath.landing,
        id: "landing",
        element: <Landing />,
      },
      {
        path: RouterPath.login,
        id: "login",
        element: <Login />,
      },
      {
        path: RouterPath.settings,
        id: "settings",
        element: (
          <MuiProvider>
            <Settings />
          </MuiProvider>
        ),
      },
      {
        path: RouterPath.calendar,
        id: "calendar",
        element: (
          <MuiProvider>
            <Calendar />
          </MuiProvider>
        ),
      },
      {
        path: RouterPath.search,
        id: "search",
        element: (
          <MuiProvider>
            <MuiSearch />
          </MuiProvider>
        ),
      },
      {
        path: RouterPath.detail,
        id: "detail",
        element: (
          <MuiProvider>
            <Detail />
          </MuiProvider>
        ),
      },
    ],
    errorElement: (
      <MuiProvider>
        <ErrorPage />
      </MuiProvider>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
/*serviceWorkerRegistration.register({
    onUpdate: registration => {
        if (registration.waiting) {
           alert('アプリを更新します。')
           registration.waiting.postMessage({type: 'SKIP_WAITING'})
        }
    }
})*/
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
