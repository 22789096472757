import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
  Toolbar,
  Unstable_Grid2 as Grid2,
} from "@mui/material";
import DisplayMode from "./parts/DisplayMode";
import FontSize from "./parts/FontSize";
import ShowPeriods from "./parts/ShowPeriods";
import SelectYear from "./parts/SelectYear";
import SwitchSemester from "./parts/SwitchSemester";

export default function Settings() {
  return (
    <Box>
      <Container
        sx={{
          my: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <Stack spacing={4}>
          <FontSize />
          <ShowPeriods />
          <SelectYear />
          <SwitchSemester />
        </Stack>
      </Container>
    </Box>
  );
}
