import { Container, Flex, Image } from "@chakra-ui/react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect } from "react";
import { Outlet, useMatches } from "react-router-dom";
import MuiProvider from "src/components/MuiProvider";
import HupassApiServer from "src/app/HupassApiServer";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import Footer from "src/components/Footer";
import Header from "src/components/Header";
import Nav from "src/components/Nav";
import {
  selectCalendar,
  setError,
  setFilters,
  setOrders,
} from "src/features/calendar/calendarSlice";
import AdBanner from "./components/application/AdBanner";

export default function App() {
  const { error } = useAppSelector((state) => selectCalendar(state));
  const dispatch = useAppDispatch();

  useEffect(() => {
    HupassApiServer.GetFilterOptions()
      .then((res) => res.data)
      .then((data) => dispatch(setFilters(data)));
    HupassApiServer.GetSortOptions()
      .then((res) => res.data)
      .then((data) => dispatch(setOrders(data)));
  }, [dispatch]);

  const matches = useMatches();
  const lastMatchId = matches[matches.length - 1].id;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [lastMatchId]);

  const matchIds = matches.map((match) => match.id);

  return (
    <>
      <Flex
        direction="column"
        pos="relative"
        pt="env(safe-area-inset-top)"
        pb="env(safe-area-inset-bottom)"
        pl="env(safe-area-inset-left)"
        pr="env(safe-area-inset-right)"
        minH="100vh"
      >
        <Header />
        <Flex
          direction={{ base: "column", md: "row-reverse" }}
          justifyContent={"center"}
          flex="auto"
        >
          <Flex flex={1} direction="column">
            <Outlet />
          </Flex>
          <Nav />
        </Flex>
      </Flex>
      <MuiProvider>
        <Dialog
          open={error.show}
          onClose={() => dispatch(setError({ ...error, show: false }))}
          PaperProps={{
            sx: {
              width: (theme) => theme.breakpoints.values.sm,
              maxWidth: "80vw",
            },
          }}
        >
          <DialogTitle>{error.title}</DialogTitle>
          <DialogContent>
            {error.contents.map((text) => (
              <DialogContentText key={text}>{text}</DialogContentText>
            ))}
          </DialogContent>
        </Dialog>
      </MuiProvider>
    </>
  );
}
