import CloseIcon from '@mui/icons-material/Close';
import { Divider, Drawer, DrawerProps, IconButton, Stack, styled, Typography } from "@mui/material";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: [theme.shape.borderRadius * 2 + 'px', theme.shape.borderRadius * 2 + 'px', 0, 0].join(" "),
        maxHeight: '80vh',
        maxWidth: theme.breakpoints.values.lg,
        margin: 'auto',
    }
}))

type Props = DrawerProps & {
    title: string,
    onClose: (event: {}, reason: 'closeButtonClick') => void
}

/**
 * 
 * @deprecated
 */
export default function BottomRoundedDrawer(props: Props) {
    const { title, children, ...drawerProps } = props

    return (
        <StyledDrawer
            anchor="bottom"
            {...drawerProps}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                    p: 2
                }}
            >
                <Typography
                    variant="h6"
                >
                    {title}
                </Typography>
                <IconButton
                    onClick={e => drawerProps.onClose(e, 'closeButtonClick')}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Divider />
            {children}
        </StyledDrawer>
    )
}