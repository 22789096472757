import { Card, CardContent, CardHeader, Container, Typography } from "@mui/material"
import { isRouteErrorResponse } from "@remix-run/router"
import { useLocation, useMatches, useRouteError } from "react-router-dom"

export default function ErrorPage() {
    const error = useRouteError()
    const location = useLocation()
    const matches = useMatches()
    if (isRouteErrorResponse(error))
        return (
            <Container>
                <Typography variant="h2">
                    {error.status + " " + error.statusText}
                </Typography>
                <Card>
                    <CardHeader
                        title="Debug Information"
                    />
                    <CardContent
                        sx={{
                            pt: 0,
                            overflow: 'scroll'
                        }}
                    >
                        <Typography variant="h6">エラー</Typography>
                        <pre>{JSON.stringify(error, null, 4)}</pre>
                        <Typography variant="h6">ロケーション</Typography>
                        <pre>{JSON.stringify(location, null, 4)}</pre>
                        <Typography variant="h6">マッチ</Typography>
                        <pre>{JSON.stringify(matches, null, 4)}</pre>
                    </CardContent>
                </Card>
            </Container>
        )
        else return (
            <Container>
                {JSON.stringify(error)}
            </Container>
        )
}