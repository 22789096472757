import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventIcon from '@mui/icons-material/Event';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SchoolIcon from '@mui/icons-material/School';
import { Box, Button, ButtonGroup, Card, CardActionArea, CardActions, CardContent, Chip, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { PeriodToStr } from '../../app/HupassApiClient';
import { ClassInfo } from '../../app/HupassApiServer';
import { registerClass, selectCalendar, unRegisterClass } from '../calendar/calendarSlice';

export default function SearchClassCard({ classinfo: cls }: { classinfo?: ClassInfo }) {
    const searchQuery = useAppSelector(state => state.search)
    const { classes } = useAppSelector(state => selectCalendar(state))
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const listProperty: {
        label: string,
        value: string | undefined | null,
        icon: JSX.Element
    }[] = [{
        label: "時限",
        value: cls?.periods.map(period => PeriodToStr(period)).join(', '),
        icon: <EventIcon />
    }, {
        label: "教員",
        value: cls?.teacher,
        icon: <SchoolIcon />
    }]

    const chipProperty: { [order: string]: (string | undefined) } = {
        relevance: cls?.faculty,
        credit: cls?.credit + " 単位",
        gpa: cls?.grade_info ? "GPA " + cls?.grade_info.gpa : undefined,
        pass_rate: cls?.grade_info ? "合格率 " +
            //取得者の合計を計算
            Math.round(Object.entries(cls.grade_info).reduce((sum, v) => (
                sum + (["ap", "a", "am", "bp", "b", "bm", "cp", "c"].includes(v[0]) ? Number(v[1]) : 0)
            ), 0) * 100 /
                cls.grade_info.numOfStudents) + '%'
            : undefined,
        A_rate: cls?.grade_info ? "A帯人数 " +
            //A帯の合計を計算
            Object.entries(cls.grade_info).reduce((sum, v) => (
                sum + (["ap", "a", "am"].includes(v[0]) ? Number(v[1]) : 0)
            ), 0) +
            "/" +
            cls.grade_info.numOfStudents
            : undefined,
    }

    return (
        <Card
            sx={{
                position: 'relative',
                pl: 0.5
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "4px",
                    height: "100%",
                    backgroundColor: classes[cls?.id ?? 0]?.color ?? "primary.main",
                }} />
            <Chip
                sx={{
                    position: 'absolute',
                    right: theme => ({ xs: theme.spacing(1.5), md: theme.spacing(2) }),
                    top: theme => ({ xs: theme.spacing(1.5), md: theme.spacing(2) }),
                    display: cls ? 'inline-flex' : 'none'
                }}
                label={chipProperty[searchQuery.order_by.join().match(/-?(.*)/)?.[1] ?? '#N/A'] ?? '#N/A'} />
            <CardActionArea
                onClick={() => cls ? navigate("/detail/" + cls.id) : null}
            >
                <CardContent>
                    <Typography variant="body2" color="text.secondary">{cls ? `${cls.year}年度 ${cls.semester}` : <Skeleton />}</Typography>
                    <Typography variant='h5' color="text.primary">{cls ? cls.subject : <Skeleton />}</Typography>
                    <Typography variant='body2' color='text.secondary'>{cls ? cls.theme : <Skeleton />}</Typography>
                    <Stack spacing={1}>
                        {listProperty.map((item, i) => (
                            <Stack direction='row' spacing={2} alignItems='center' sx={{ color: 'text.secondary' }} key={i}>
                                {item.icon}
                                <Typography key={i} sx={{ width: '100%' }}>{cls ? item.value : <Skeleton />}</Typography>
                            </Stack>
                        ))}
                        <Typography variant='body2' color='text.secondary'>{cls ? cls.faculty + ' ' + (cls.elig_dep ?? '') : <Skeleton />}</Typography>
                    </Stack>
                </CardContent>
            </CardActionArea>
            <Divider />
            <CardActions
                sx={{
                    justifyContent: "space-between",
                }}>
                <ButtonGroup
                    variant='outlined'
                >
                    <Button
                        //endIcon={<ChevronRightRoundedIcon />}
                        href={`http://syllabus01.academic.hokudai.ac.jp/Syllabi/Public/Syllabus/SylDetail.aspx?lct_year=${cls?.year}&lct_cd=${('000000' + cls?.cls_id?.toString()).slice(-6)}&langMode=1`} //'https://moodle.elms.hokudai.ac.jp/course/view.php?id=112180'
                        disabled={cls?.cls_id ? false : true}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        シラバス
                    </Button>
                    <Button
                        //endIcon={<ChevronRightRoundedIcon />}
                        href={`https://moodle.elms.hokudai.ac.jp/course/view.php?id=${cls?.moodle_id}`} //'https://moodle.elms.hokudai.ac.jp/course/view.php?id=112180'
                        disabled={cls?.moodle_id ? false : true}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Moodle
                    </Button>
                </ButtonGroup>
                <Button
                    variant="contained"
                    startIcon={cls ? classes[cls.id] ? <RemoveCircleIcon /> : <AddCircleIcon /> : <Skeleton variant="circular" />}
                    color={cls && classes[cls.id] ? "warning" : "primary"}
                    sx={{ flexShrink: 0 }}
                    onClick={() => cls ? classes[cls.id] ?
                        dispatch(unRegisterClass(cls)) :
                        registerClass(cls).then(appthunk => dispatch(appthunk)) : null
                    }
                    disableElevation
                >
                    {cls ? classes[cls.id] ? "削除" : "追加" : <Skeleton sx={{ minWidth: "2em" }} />}
                </Button>
            </CardActions>
        </Card >
    )
}