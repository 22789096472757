import {
  ChakraProvider as ChakraOriginalProvider,
  ThemeConfig,
  extendTheme,
  useColorMode,
} from "@chakra-ui/react";
import { PropsWithChildren, memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  selectSettings,
  setDisplayMode,
} from "../features/settings/settingsSlice";

export default memo(function ChakraProvider({ children }: PropsWithChildren) {
  const { isDarkMode } = useAppSelector((state) => selectSettings(state));

  const config: ThemeConfig = {
    initialColorMode: isDarkMode ? "light" : "dark",
    useSystemColorMode: false,
  };

  const chakraTheme = extendTheme(config);

  chakraTheme.fonts.body =
    '"Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif';
  chakraTheme.fonts.heading =
    '"Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif';

  return (
    <ChakraOriginalProvider theme={chakraTheme}>
      <ColorChangeSyncer />
      {children}
    </ChakraOriginalProvider>
  );
});

const ColorChangeSyncer = memo(() => {
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();

  useEffect(() => {
    dispatch(setDisplayMode(colorMode));
  }, [colorMode]);

  return <></>;
});
