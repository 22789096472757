import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectCalendar, setYear } from "../../../features/calendar/calendarSlice";

export default function SelectYear() {
    const { year, filters } = useAppSelector(state => selectCalendar(state))
    const dispatch = useAppDispatch();

    return (
        <FormControl>
            <Typography
                gutterBottom
                color='text.secondary'
                variant='body2'
            >
                年度選択
            </Typography>
            <Select
                value={year}
                onChange={e => dispatch(setYear(Number(e.target.value)))}
            >
                {filters.filter(item => item.name === 'year').map(
                    item => item.choices.map(
                        ([value, label]) => (
                            <MenuItem
                                key={value}
                                value={value}
                            >
                                {label}
                            </MenuItem>
                        )
                    )
                )}
            </Select>
        </FormControl>
    )
}