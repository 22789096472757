import AddCircleIcon from '@mui/icons-material/Add'
import RoomIcon from '@mui/icons-material/Room'
import SchoolIcon from '@mui/icons-material/School'
import { alpha, Box, Button, ButtonGroup, Card, CardActionArea, CardActions, CardContent, CardHeader, List, ListItem, ListItemText, Stack } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { ColorCardOpacity, SemesterToStr } from '../../app/HupassApiClient'
import { MyClassInfo } from '../../app/HupassApiServer'
import { SearchStateKey, setQuery } from '../search/searchSlice'
import { selectSettings } from '../settings/settingsSlice'

type Props = {
    weeklyView: boolean,
    day: number,
    period: number,
    id: number
}

const CalendarCard = styled(Card)(({ theme }) => ({
    flexGrow: 1,
    position: "relative",
    borderRadius: 4,
    height: '100%',
}))

export default function CalendarClassCard({ weeklyView, day, period, id }: Props) {
    const { year, semester, classes } = useAppSelector(state => state.calendar)
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const { fontSize } = useAppSelector(state => selectSettings(state))
    const ratio = { sm: 0.9, md: 1, lg: 1.2 }[fontSize]
    const mycls = classes[id]

    const query: {
        key: SearchStateKey,
        value: string[]
    }[] = [{
        key: "year",
        value: [String(year)],
    }, {
        key: "semester",
        value: [SemesterToStr(semester)],
    }, {
        key: "period",
        value: [(day + 1) + "-" + (period + 1)],
    }]

    if (!mycls) return (
        <CalendarCard
            variant='outlined'
        >
            <CardActionArea
                sx={{
                    height: '100%'
                }}
                onClick={() => query.forEach((q) => dispatch(setQuery(q)))}
                component={Link}
                to='/search'
            >
                <CardContent
                    sx={{
                        height: '100%'
                    }}
                >
                    <Stack
                        sx={{
                            height: "100%"
                        }}
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <AddCircleIcon
                            color='action'
                        />
                    </Stack>
                </CardContent>
            </CardActionArea>
        </CalendarCard>
    )

    const { cls, color, c_place, c_teacher, c_moodle_id, c_subject, c_theme }: MyClassInfo = mycls

    return (
        <CalendarCard
            sx={{
                pl: theme.spacing(color ? 0.5 : 0),
                backgroundImage: `linear-gradient(${alpha(color, ColorCardOpacity)}, ${alpha(color, ColorCardOpacity)})`,
                fontSize: { xs: 11 * ratio, sm: 12.5 * ratio, md: 14 * ratio, lg: 15 * ratio },
                zIndex: 1,
            }}
            elevation={1}
        >
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: theme.spacing(color ? 0.5 : 0),
                    height: "100%",
                    backgroundColor: color ? color : "transparent",
                }} />
            <CardActionArea
                sx={{
                    height: "100%",
                    position: 'relative'
                }}
                component={Link}
                to={'/detail/' + cls.id}
            >
                <CardHeader
                    title={c_subject ?? cls?.subject}
                    titleTypographyProps={{
                        fontSize: '1em',
                        fontWeight: 'bold',
                        sx: {
                            display: '-webkit-box',
                            WebkitBoxOrient: "vertical",
                            lineClamp: "2",
                            WebkitLineClamp: "2",
                            overflow: 'hidden'
                        }
                    }}
                    subheader={c_theme ?? cls.theme}
                    subheaderTypographyProps={{
                        fontSize: '0.9em',
                        sx: {
                            display: '-webkit-box',
                            WebkitBoxOrient: "vertical",
                            lineClamp: "2",
                            WebkitLineClamp: "2",
                            overflow: 'hidden'
                        }
                    }}
                    sx={{
                        p: { xs: weeklyView ? 0.5 : 1, sm: 1 },
                    }}
                />
                <CardContent
                    sx={{
                        p: { xs: weeklyView ? 0.5 : 1, sm: 1 },
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <List
                        disablePadding
                        sx={{ mt: 'auto', flexShrink: 0 }}
                    >
                        {[{
                            icon: <RoomIcon fontSize='small' />,
                            text: c_place ?? cls?.place,
                        }, {
                            icon: <SchoolIcon fontSize='small' />,
                            text: c_teacher ?? cls?.teacher,
                        },].map(({ icon, text }, i) => (
                            text ? <ListItem dense disablePadding key={i}>
                                <ListItemText
                                    sx={{ my: 0 }}
                                    primaryTypographyProps={{
                                        sx: { fontSize: '0.9em', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }
                                    }}
                                    primary={text}>
                                </ListItemText>
                            </ListItem> : null
                        ))}
                    </List>
                </CardContent>
            </CardActionArea>
            <CardActions
                sx={{
                    display: true ? 'none' : { xs: weeklyView ? 'none' : 'flex', lg: 'flex' },
                    flexShrink: 0,
                    flexGrow: 0,
                }}
            >
                <ButtonGroup
                    size='small'
                    variant='text'
                    fullWidth
                    color='inherit'
                >
                    <Button
                        //endIcon={<ChevronRightRoundedIcon />}
                        href={`https://moodle.elms.hokudai.ac.jp/course/view.php?id=${c_moodle_id ?? cls?.moodle_id}`} //'https://moodle.elms.hokudai.ac.jp/course/view.php?id=112180'
                        disabled={cls?.moodle_id ? false : true}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Moodle
                    </Button>
                    <Button
                        //endIcon={<ChevronRightRoundedIcon />}
                        href={`https://www.elms.hokudai.ac.jp/group/grouppage?idnumber=p${cls?.year.toString().slice(-2) + ('000000' + cls?.cls_id?.toString()).slice(-6)}`} //'https://www.elms.hokudai.ac.jp/group/grouppage?idnumber=p22015607'
                        disabled={cls?.cls_id ? false : true}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ELMS
                    </Button>
                </ButtonGroup>
            </CardActions>
        </CalendarCard >
    )
}