import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThisYear } from '../../app/HupassApiClient';

export type SearchStateKey =
    "period" |
    "year" |
    "semester" |
    "faculty" |
    "keyword" |
    "order_by"


export type SearchState = {
    [key in SearchStateKey]: string[]
}

const prefix = "search_"
const readLocalStrage = (key: SearchStateKey): any[] | null => JSON.parse(localStorage.getItem(prefix + key) ?? 'null')
const writeLocalStrage = (key: SearchStateKey, value: any[]) => localStorage.setItem(prefix + key, JSON.stringify(value))

const initialState: SearchState = {
    period: readLocalStrage('period') ?? [],
    year: readLocalStrage('year') ?? [ThisYear.toString()],
    semester: readLocalStrage('semester') ?? [],
    faculty: readLocalStrage('faculty') ?? [],
    keyword: readLocalStrage('keyword') ?? [],
    order_by: readLocalStrage('order_by') ?? ['relevance']
}

const setValue = (key: SearchStateKey, value: string[], state: SearchState) => {
    state[key] = value;
    writeLocalStrage(key, value);
}

export const searchSlice = createSlice({
    name: 'search',
    initialState: initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<{ key: SearchStateKey, value: string[] }>) => {
            let { key, value } = action.payload;
            setValue(key, value, state)
        },
        addQuery: (state, action: PayloadAction<{ key: SearchStateKey, value: string[] | string }>) => {
            const { key, value } = action.payload;
            setValue(key, state[key].concat(value), state);
        },
        removeQuery: (state, action: PayloadAction<{ key: SearchStateKey, value: string[] | string }>) => {
            const { key, value } = action.payload;
            setValue(key, state[key].filter(v => !(Array.isArray(value) ? value : [value]).includes(v)), state)
        }
    },
})

// Action creators are generated for each case reducer function
export const { setQuery, addQuery, removeQuery } = searchSlice.actions

export default searchSlice.reducer