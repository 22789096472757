import { Box, Button, Container, Flex, IconButton, Portal, Text, useColorModeValue } from '@chakra-ui/react'
import { useRef } from 'react'
import { IoCalendar, IoCalendarOutline, IoHome, IoHomeOutline, IoNewspaper, IoNewspaperOutline, IoSearch, IoSearchOutline, IoSettings, IoSettingsOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { useMatches } from 'react-router-dom'
import { RouterPath } from 'src'

type NavInfo = {
    id: string,
    icon: React.ReactElement,
    outlineIcon: React.ReactElement,
    text: string,
    link: string,
}

export default function Nav() {
    const ref = useRef<HTMLDivElement>(null)
    const matches = useMatches()
    const bgBlue = useColorModeValue('blue.500', 'blue.600')

    const navs: NavInfo[] = [{
        id: 'landing',
        icon: <IoHome />,
        outlineIcon: <IoHomeOutline />,
        text: 'Hupass',
        link: RouterPath.landing
    }, {
        id: 'calendar',
        icon: <IoCalendar />,
        outlineIcon: <IoCalendarOutline />,
        text: 'Timetable',
        link: RouterPath.calendar
    }, {
        id: 'search',
        icon: <IoSearch />,
        outlineIcon: <IoSearchOutline />,
        text: 'Search',
        link: RouterPath.search
    }, {
        id: 'settings',
        icon: <IoSettings />,
        outlineIcon: <IoSettingsOutline />,
        text: 'Settings',
        link: RouterPath.settings
    }]

    return (
        <Box
            pos={{ base: 'sticky', xl: 'relative' }}
            bottom={0}
            left={0}
            right={0}
            pl='env(safe-area-inset-left)'
            pr='env(safe-area-inset-right)'
            pb='env(safe-area-inset-bottom)'
            mb={'calc(env(safe-area-inset-bottom) * -1)'}
            bgColor={useColorModeValue('white', 'gray.800')}
            borderTopWidth={{ base: 1, md: 0 }}
            borderRightWidth={{ base: 0, md: 1 }}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.900')}
            zIndex={'sticky'}
        >
            <Container
                ref={ref}
                pos={{ base: 'relative', md: 'sticky' }}
                top={0}
                h={{ base: 14, md: 'auto' }}
                w={{ base: '100%', md: 16 }}
                maxW={'7xl'}
                as={Flex}
                direction={{ base: 'row', md: 'column' }}
                justifyContent={{ base: 'space-around', md: 'flex-start' }}
                alignItems={'center'}
                gap={2}
                p={3}
            >
                {navs.map(info => (
                    matches.some(({ id }) => id === info.id) ? (
                        <Button
                            key={info.id}
                            flexGrow={{ base: 1, md: 0 }}
                            flexBasis={{ base: 1, md: 'unset' }}
                            aria-label={info.text}
                            variant={'ghost'}
                            rounded={'xl'}
                            colorScheme='blue'
                            flexDirection={{ base: 'column', md: 'row' }}
                            columnGap={2}
                            rowGap={1}
                            as={Link}
                            to={info.link}
                            p={0}
                            pos={'relative'}
                            _hover={{
                                bgColor: 'transparent'
                            }}
                            _before={{
                                content: "''",
                                pos: 'absolute',
                                top: { base: -2, md: 0 },
                                mt: { base: '-1px', md: 0 },
                                right: {base: 0, md: -3},
                                width: {base: '100%', md: 1},
                                height: {base: 1, md: '100%'},
                                bgColor: bgBlue,
                                borderBottomRightRadius: {base: 'md', md: 'none'},
                                borderBottomLeftRadius: 'md',
                                borderTopLeftRadius: {base: 0, md: 'md'}
                            }}
                        >
                            {info.icon}
                            <Text
                                fontSize={{ base: '2xs', md: 'sm' }}
                                display={{ base: 'inline-block', md: 'none' }}
                            >
                                {info.text}
                            </Text>
                        </Button>
                    ) : (
                        <IconButton
                            key={info.id}
                            flexGrow={{ base: 1, md: 0 }}
                            flexBasis={{ base: 1, md: 'unset' }}
                            aria-label={info.text}
                            icon={info.outlineIcon}
                            variant={'ghost'}
                            rounded={'xl'}
                            as={Link}
                            to={info.link}
                        />
                    )
                ))}
                {/*
                <Button
                    aria-label='Home'
                    variant={'ghost'}
                    colorScheme='blue'
                    w={'full'}
                    rounded={'2xl'}
                    flexDirection={{ base: 'column', md: 'row' }}
                    columnGap={2}
                >
                    <IoHome />
                    <Text
                        fontSize={{ base: '2xs', md: 'sm' }}
                    >
                        Home
                    </Text>
                </Button>
                <IconButton
                    aria-label='Home'
                    variant={'ghost'}
                    w={'full'}
                    rounded={'2xl'}
                >
                    <IoCalendarOutline />
                </IconButton>
                <IconButton
                    aria-label='Home'
                    variant={'ghost'}
                    rounded={'2xl'}
                    w={'full'}
                >
                    <IoSearchOutline />
                </IconButton>
                <IconButton
                    aria-label='Home'
                    variant={'ghost'}
                    rounded={'2xl'}
                    w={'full'}
                >
                    <IoNewspaperOutline />
                </IconButton>
                <IconButton
                    aria-label='Home'
                    variant={'ghost'}
                    rounded={'2xl'}
                    w={'full'}
                >
                    <IoSettingsOutline />
                </IconButton>*/}
            </Container>
        </Box>
    )
}
