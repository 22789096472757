import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Skeleton, Typography } from "@mui/material";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useAppSelector } from "../../app/hooks";
import { ColorIndicatorWidth } from '../../app/HupassApiClient';
import { ClassInfo } from "../../app/HupassApiServer";
import { selectCalendar } from "../calendar/calendarSlice";

type Props = {
    cls?: ClassInfo
}

export default function DetailGrades({ cls }: Props) {
    const { classes } = useAppSelector(state => selectCalendar(state))
    const mycls = classes[cls?.id ?? 0]

    const colors = {
        A: '#2196f3',
        B: '#bbdefb',
        C: '#ff9800',
        D: '#f44336',
        F: '#f44336',
    }

    const chartData = [
        {
            name: 'A+',
            value: cls?.grade_info?.ap,
            color: colors.A,
        },
        {
            name: 'A',
            value: cls?.grade_info?.a,
            color: colors.A,
        },
        {
            name: 'A-',
            value: cls?.grade_info?.am,
            color: colors.A,
        },
        {
            name: 'B+',
            value: cls?.grade_info?.bp,
            color: colors.B,
        },
        {
            name: 'B',
            value: cls?.grade_info?.b,
            color: colors.B,
        },
        {
            name: 'B-',
            value: cls?.grade_info?.bm,
            color: colors.B,
        },
        {
            name: 'C+',
            value: cls?.grade_info?.cp,
            color: colors.C,
        },
        {
            name: 'C',
            value: cls?.grade_info?.c,
            color: colors.C,
        },
        {
            name: 'D',
            value: cls?.grade_info?.d,
            color: colors.D,
        },
        {
            name: 'D-',
            value: cls?.grade_info?.dm,
            color: colors.D,
        },
        {
            name: 'F',
            value: cls?.grade_info?.f,
            color: colors.F,
        },
    ]

    if (!cls?.grade_info) return (
        <Box>

        </Box>
    )

    return (
        <Box>
            <Typography
                variant='body2'
                color='text.secondary'
                sx={{
                    my: 1, px: 2.5
                }}
            >
                成績情報
            </Typography>
            <Card
                sx={{
                    position: 'relative',
                    pl: ColorIndicatorWidth,
                    '& .recharts-text': {
                        fill: theme => theme.palette.text.secondary
                    },
                    '& .recharts-cartesian-grid line': {
                        stroke: theme => theme.palette.divider
                    }
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: ColorIndicatorWidth,
                        height: "100%",
                        backgroundColor: mycls?.color ?? "primary.main",
                    }} />
                <CardHeader
                    title={cls ? (cls.grade_info?.year + "年度 " + cls.grade_info?.semester + " " + cls.grade_info?.subject ?? cls.subject) : <Skeleton />}
                    subheader={cls ? cls.grade_info.teacher ?? cls.theme : <Skeleton />}
                />
                <CardContent
                    sx={{
                        pt: 0,
                    }}
                >
                    <ResponsiveContainer height={300}>
                        <BarChart
                            data={chartData}
                        >
                            <XAxis
                                dataKey={'name'}
                                axisLine={false}
                                tickLine={false}
                            //stroke={theme.palette.text.secondary}
                            />
                            <YAxis
                                label={{ value: '人数', angle: -90, position: 'insideLeft' }}
                                axisLine={false}
                                tickLine={false}
                            //stroke={theme.palette.text.secondary}
                            />
                            <CartesianGrid
                                vertical={false}
                            //stroke={theme.palette.divider}
                            />
                            <Bar
                                dataKey='value'
                                name='人数'
                                radius={4}
                            >
                                {chartData.map((item, i) => (
                                    <Cell fill={item.color} key={i} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </CardContent>
                <CardActions
                    sx={{
                        justifyContent: "space-between"
                    }}
                >
                    <ButtonGroup
                        variant='outlined'
                    >
                        <Button
                            href={`https://inazo.hu-jagajaga.com/detail/${cls?.grade_info.id}`} //'https://moodle.elms.hokudai.ac.jp/course/view.php?id=112180'
                            disabled={!Boolean(cls?.grade_info.id)}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                textTransform: 'none'
                            }}
                        >
                            iNAZO
                        </Button>
                        <Button
                            //endIcon={<ChevronRightRoundedIcon />}
                            href={`https://educate.academic.hokudai.ac.jp/seiseki/GradeDistSerch.aspx`} //'https://moodle.elms.hokudai.ac.jp/course/view.php?id=112180'
                            //disabled={cls?.moodle_id ? false : true}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            公式データ
                        </Button>
                    </ButtonGroup>
                </CardActions>
            </Card>
        </Box >
    )
}