import { Container, IconButton, Stack, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import HupassApiServer from "../../app/HupassApiServer";
import { ClassInfo } from "../../app/HupassApiServer";
import { selectCalendar } from "../calendar/calendarSlice";
import DetailClassInformation from './DetailClassInformation';
import DetailGrades from './DetailGrades';
import DetailMemo from './DetailMemo';
import { IoArrowBack } from "react-icons/io5";

export default function Detail() {
    const { id: id_str } = useParams()
    const id = Number(id_str);
    const { classes, mode } = useAppSelector(state => selectCalendar(state))
    const [cls, setCls] = useState<ClassInfo>()
    const navigate = useNavigate()

    useEffect(() => {
        HupassApiServer.GetClass(id)
            .then(res => res.data)
            .then(data => setCls(data))
            .catch(e => console.error(e))
    }, [id, classes, mode])

    return (
        <Container
            sx={{
                my: 2
            }}
        >
            <Toolbar>
                <IconButton
                    edge='start'
                    onClick={() => navigate(-1)}
                >
                    <IoArrowBack />
                </IconButton>
            </Toolbar>
            <Stack
                spacing={2}
            >
                <DetailClassInformation cls={cls} />
                <DetailMemo cls={cls} />
                <DetailGrades cls={cls} />
            </Stack>
        </Container >
    )
}