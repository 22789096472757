import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import settingsReducer from '../features/settings/settingsSlice';
import calendarReducer from '../features/calendar/calendarSlice';
import searchReducer from '../features/search/searchSlice';

export const store = configureStore({
  reducer: {
    calendar: calendarReducer,
    search: searchReducer,
    settings: settingsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
