import { ClassInfo, filterQuery, Period } from './HupassApiServer';


// 主にクライアント用のAPIの記述
// 定数を設置することによる設定もここへ



///////////////////
// 定数
///////////////////

/** 今日 */
export const Today = new Date();
/** 今年度 */
export const ThisYear = Today.getFullYear() - Number(Today.getMonth() < 4);
/** 今学期 */
export const ThisSemester = (Math.floor((Today.getMonth()) / 3 + 3) % 4 + 1)
/** FlatUI Colors */
export const FlatColors = {
    turquoise: '#1abc9c',
    greenSea: '#16a085',
    emerald: '#2ecc71',
    nephritis: '#27ae60',
    peterRiver: '#3498db',
    belizeHole: '#2980b9',
    amethyst: '#9b59b6',
    wisteria: '#8e44ad',
    wetAsphalt: '#34495e',
    midnightBlue: '#2c3e50',
    sunFlower: '#f1c40f',
    orange: '#f39c12',
    carrot: '#e67e22',
    pumpkin: '#d35400',
    alizarin: '#e74c3c',
    pomegranate: '#c0392b',
    clouds: '#ecf0f1',
    silver: '#bdc3c7',
    concrete: '#95a5a6',
    asbestos: '#7f8c8d',
}
/** 色付きカードの透明度 */
export const ColorCardOpacity = 0.08
/** カラーインディケーターの幅 カレンダーとか検索結果の左のアレ */
export const ColorIndicatorWidth = '4px'
/** 似ている色を避けるカレンダー半径 */
export const NotSimilarColorRadius = 2

///////////////////
// 関数
///////////////////

/** 
 * TokenがCookieに存在するかどうか
 * @return 存在するかどうか
 */
export const existsToken = () => Boolean(document.cookie.match(/token=/))
/**
 * TokenをCookiesから削除
 * @returns Cookieの文字列
 */
export const removeToken = () => document.cookie = "token=; max-age=0;"

/** 
 * 学期文字列を学期bitに変換
 * @param str 学期文字列
 * @return 学期bit
 */
export const StrToSemesterBit = (str: string | number): number => {
    switch (str) {
        case "１学期":
            return 0b0011
        case "２学期":
            return 0b1100
        case 1:
        case "１学期（春ターム）":
            return 0b0001
        case 2:
        case "１学期（夏ターム）":
            return 0b0010
        case 3:
        case "２学期（秋ターム）":
            return 0b0100
        case 4:
        case "２学期（冬ターム）":
            return 0b1000
        case "通年":
            return 0b1111
        default:
            return 0b10000
    }

}

export const StrToSemester = (str: string): number[] => {
    return [1, 2, 3, 4].filter(
        semester => StrToSemesterBit(semester) & StrToSemesterBit(str)
    )
}

/** 
 * 学期のインデックスを文字列に変換
 * @param semester 学期のインデックス [1-4]
 * @return 学期の文字列
 */
export const SemesterToStr = (semester: number): string => {
    return ["１学期（春ターム）", "１学期（夏ターム）", "２学期（秋ターム）", "２学期（冬ターム）", "通年"][semester - 1]
}

/**
 * 時限の文字列を曜日・時間のインデックスに変換
 * @param period 時限
 * @param days 曜日の配列
 * @returns 曜日・時間のインデックス 0から始まってエラー時には-1を返す
 */
export const StrToPeriod = (period: string, days: string[] = ['月', '火', '水', '木', '金']): Period => {
    const split = period.split('')
    if (split.length !== 2) return { day: -1, period: -1 }
    return { day: (days.indexOf(split[0]) ?? -2) + 1, period: Number(split[1]) || -1 }
}

/**
 * 時限の曜日・時間のインデックスを文字列に変換
 * @param period 時限
 * @param days 曜日の配列
 * @returns 曜日・時間の文字列
 */
export const PeriodToStr = (period: Period, days: string[] = ['月', '火', '水', '木', '金']): string => {
    return days[period.day - 1] + period.period
}

/**
 * クラスがクエリにマッチするかどうかをチェック
 * @param cls チェックするクラス
 * @param query チェックするクエリ
 * @returns マッチするかどうか
 */
export const isMatch = (cls: ClassInfo, query: filterQuery): boolean => {
    const { id, year, semester, semester_int, period } = query
    const semester_bit = semester ? StrToSemesterBit(semester) : semester_int ? (1 << (semester_int - 1)) : 0b1111

    return (
        (id ? cls.id === id : true) && //!console.log("id passed", cls.id, id) &&
        (year ? cls.year === year : true) && //!console.log("year passed", cls.year, year) &&
        Boolean(StrToSemesterBit(cls.semester) & semester_bit) && //!console.log("semester passed", semesterConverter.StrToSemesterBit(cls.semester), semester_bit) &&
        Boolean(period ? cls.periods.filter(cp => (
            period.filter(p => (
                p === cp
            )).length
        )).length : true)
    )
}
