import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ToggleButtonSetting from "../base/ToggleButtonSetting";
import { selectSettings, setShowPeriods } from "../settingsSlice";

export default function ShowPeriods() {
    const { showPeriods } = useAppSelector(state => selectSettings(state))
    const dispatch = useAppDispatch()
    const handleChange = (e: React.MouseEvent, value: typeof showPeriods) => dispatch(setShowPeriods(value ?? showPeriods))

    return (
        <ToggleButtonSetting
            title='時間の表示'
            value={showPeriods}
            onChange={handleChange}
            options={[{
                label: 'オン',
                value: 'on',
            },{
                label: '自動',
                value: 'auto',
            },{
                label: 'オフ',
                value: 'off',
            }]}
        />
    )
}