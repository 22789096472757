import { Button, Stack, styled, Toolbar, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectSettings } from "../settings/settingsSlice";
import CalendarClassCard from "./CalendarClassCard";
import { selectCalendar, setClasses, setDay, setWeeklyView } from "./calendarSlice";
import HupassApiServer from "src/app/HupassApiServer";
import { existsToken } from "src/app/HupassApiClient";

const NoScrollBarStack = styled(Stack)(() => ({
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

export default function Calendar() {
  /** クラス情報 */
  const { year, semester, days, periods, timetable, weeklyView, day } =
    useAppSelector((state) => selectCalendar(state));
  const { showPeriods } = useAppSelector((state) => selectSettings(state));

  const [scrollInto, setScrollInto] = useState<number>();

  const swipeableArea = useRef<HTMLDivElement>(null);
  const taskId = useRef<NodeJS.Timeout>();
  const dispatch = useAppDispatch();
  const isLogin = existsToken()

  const classesInView =
    timetable[year]?.[semester] ?? days.map(() => periods.map(() => 0));

  useEffect(() => {
    if (isLogin) {
      HupassApiServer.ListMyClasses()
        .then((response) => response.data)
        .then((data) => {
          dispatch(setClasses(data.results));
        });
    }
  }, [isLogin, dispatch]);

  // スクロール禁止
  // useLayoutEffect(() => {
  //     Array.from(document.getElementsByTagName('html')).forEach(el => el.style.overflow = 'hidden')
  //     return () => {
  //         Array.from(document.getElementsByTagName('html')).forEach(el => el.style.overflow = '')
  //     }
  // }, [])

  // weeklyからDailyに切り替わった後に曜日の場所にスクロール
  // useEffect()でレンダリング完了後に実行することで正確にスクロールできる
  useEffect(() => {
    if (!weeklyView)
      swipeableArea.current?.children[day]?.scrollIntoView({
        behavior: "auto",
      });
  }, [weeklyView]);

  // スクロール時に曜日を変更
  useEffect(() => {
    if (swipeableArea.current === null) return;
    const rootEl: HTMLElement = swipeableArea.current;

    let callback: IntersectionObserverCallback;
    if (scrollInto !== undefined) {
      // scrollIntoまでスクロール
      rootEl.children[scrollInto]?.scrollIntoView({ behavior: "smooth" });
      dispatch(setDay(scrollInto));
      // 一定時間経ったらscrollIntoを消去し、その場所を選択
      // スクロール中にスクロールを止めた時対策
      clearTimeout(taskId.current);
      taskId.current = setTimeout(() => {
        setScrollInto(undefined);
        dispatch(setDay(Math.round(rootEl.scrollLeft / rootEl.clientWidth)));
      }, 1000);
      // 目的の要素までスクロールしたらscrollIntoをリセット
      callback = (entries) => {
        if (
          entries.length === 1 &&
          Array.from(rootEl.children).indexOf(entries[0].target) === scrollInto
        )
          setScrollInto(undefined);
      };
    } else {
      // スクロールされた曜日を選択
      callback = (entries) => {
        if (entries.length === 1)
          dispatch(
            setDay(Array.from(rootEl.children).indexOf(entries[0].target))
          );
      };
    }

    const observer = new IntersectionObserver(callback, {
      root: rootEl,
      rootMargin: "0px -33.3%",
      threshold: 0.333,
    });

    // 監視開始
    Array.from(rootEl.children).forEach((el: Element) => observer.observe(el));
    return () => {
      // 監視終了
      Array.from(rootEl.children).forEach((el: Element) =>
        observer.unobserve(el)
      );
    };
  }, [scrollInto, dispatch]);

  return (
    <Container
      sx={{
        //height: { xs: "550px", md: "700px" },
        py: 1,
        px: { xs: "2px", sm: 1, md: 2 },
        display: "grid",
        gridTemplateColumns: "auto repeat(5, 1fr)",
        gridTemplateRows: "auto 1fr auto",
        rowGap: (theme) => theme.spacing(0.5),
        flexGrow: 1,
      }}
    >
      {days.map((v, i) => (
        // 曜日ごとのボタン
        <Button
          sx={{
            mx: "auto",
            minWidth: 0,
            height: (theme) => theme.spacing(5),
            width: (theme) => theme.spacing(5),
            borderRadius: (theme) => theme.spacing(2.5),
            gridArea: "1 / " + (i + 2),
          }}
          variant={day === i && !weeklyView ? "contained" : "text"}
          color={day === i && !weeklyView ? "primary" : "primary"}
          onClick={() => {
            if (weeklyView) {
              dispatch(setDay(i));
              dispatch(setWeeklyView(false));
            } else if (day === i) dispatch(setWeeklyView(true));
            else setScrollInto(i);
          }}
          key={i}
        >
          {v}
        </Button>
      ))}
      <Stack
        spacing={1}
        sx={{
          gridArea: "2 / 1",
          display: {
            xs: { on: "flex", auto: "none", off: "none" }[showPeriods],
            sm: { on: "flex", auto: "flex", off: "none" }[showPeriods],
          },
          pr: 0.5,
        }}
      >
        {periods.map((period, i) => (
          // 時間表示
          <Stack flexGrow={1} justifyContent="space-between" key={i}>
            {period.map((p, i) => (
              <Typography
                variant="body2"
                color="text.secondary"
                fontSize={{ xs: "0.7rem", sm: "0.8rem" }}
                key={i}
                textAlign="right"
              >
                {p}
              </Typography>
            ))}
          </Stack>
        ))}
      </Stack>
      <NoScrollBarStack
        direction="row"
        flexWrap="nowrap"
        sx={{
          gridArea: "2 / 2 / 2 / 7",
          overflow: "auto",
          scrollSnapType: "x mandatory",
        }}
        ref={swipeableArea}
      >
        {classesInView.map((week, day_int) => (
          <Stack
            spacing={{ xs: 0.5, sm: 0.6, md: 0.8 }}
            flexShrink={0}
            key={day_int}
            sx={{
              p: { xs: 0.25, sm: 0.3, md: 0.4 },
              width: weeklyView ? "20%" : "100%",
              scrollSnapStop: "always",
              scrollSnapAlign: "center",
              zIndex: 1,
            }}
          >
            {week.map((id, period_int) => (
              <CalendarClassCard
                weeklyView={weeklyView}
                day={day_int}
                period={period_int}
                id={id}
                key={period_int}
              />
            ))}
          </Stack>
        ))}
      </NoScrollBarStack>
    </Container>
  );
}
