import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import {
  BackgroundProps,
  PositionProps,
  SystemStyleObject,
  useColorModeValue,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";

const getLocalStorageValue = <S>(key: string, initValue: S): S => {
  let item = null;
  try {
    item = JSON.parse(localStorage.getItem(key) || "null");
  } catch (error) {
    console.error(error);
  }

  return item ? item : initValue;
};

export const useLocalStorage = <S>(key: string, initValue: S | (() => S)) => {
  const [value, setValue] = useState<S>(() =>
    getLocalStorageValue(
      key,
      initValue instanceof Function ? initValue() : initValue
    )
  );

  const jsonValue = JSON.stringify(value);

  const setLocalStorageValue = useCallback(
    (setStateAction: S | ((prevState: S) => S)) => {
      const newValue =
        setStateAction instanceof Function
          ? setStateAction(JSON.parse(jsonValue))
          : setStateAction;

      localStorage.setItem(key, JSON.stringify(newValue));
      setValue(() => newValue);
    },
    [key, jsonValue]
  );

  localStorage.setItem(key, JSON.stringify(value));

  return [value, setLocalStorageValue] as const;
};

export const useColorShadow = (
  color: BackgroundProps["bgColor"],
  offset: PositionProps["bottom"] & PositionProps["top"] = 2,
  size: number = 18
): PositionProps & { _before: SystemStyleObject } => ({
  position: "relative",
  zIndex: 0,
  _before: {
    content: "''",
    bgColor: color,
    filter: `blur(${size}px)`,
    pos: "absolute",
    top: offset,
    left: size + "px",
    right: size + "px",
    height: "100%",
    zIndex: -1,
    opacity: useColorModeValue(0.8, 0.2),
    rounded: "inherit",
  },
});

export const useGrayText = () => useColorModeValue("gray.500", "gray.400");
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
