import axios from 'axios';
//import { ClassInfo, InfoList, MyClassInfo, Period, SearchFilter, SortOrder, UserInfo } from './HupassApiServer';


// 主にサーバーとの通信用のAPI

/** 成績 */
export type Grade =
'ap' |
'a' |
'am' |
'bp' |
'b' |
'bm' |
'cp' |
'c' |
'd' |
'dm' |
'f'

/** 成績情報の成績人数のみ */
export type Grades = {
[keys in Grade]: number
}


/** 成績情報 */
export type GradeInfo = Grades & {
/** 成績情報データベースのID */
id: number,
/** 科目名 */
subject: string,
/** 講義題目 */
lecture: string,
/** グループ */
group: string,
/** 担当教員 */
teacher: string,
/** 開講年度 */
year: string,
/** 開講期間 */
semester: string,
/** 学部 */
faculty: string,
/** 受講生徒数 */
numOfStudents: number,
/** 平均GPA */
gpa: number,
}

/** 時限情報 */
export type Period = {
id?: number,
period: number,
day: number
}

/** ClassInfoのキーワード */
export type ClassKeyword = {
id: number,
cls: number,
keyword: string,
}

/** クラス情報 */
export type ClassInfo = {
/** クラスデータベースID */
id: number,
/** 科目名 */
subject: string,
/** 講義題目 */
theme: string,
/** 登録しているかどうか */
is_enrolled: boolean,
/** 担当教員 */
teacher: string,
/** 開講年度 */
year: number,
/** 開講期間 */
semester: string,
/** 講義室 */
place: string,
/** 対象年次(低) */
elig_min: number | null,
/** 対象年次(高) */
elig_max: number | null,
/** 対象学科 */
elig_dep: string,
/** 単位数 */
credit: number,
/** 科目種別 */
sort: string,
/** 大分類コード */
big_code: string,
/** ナンバリングコード */
code: number,
/** 時間割番号 */
cls_id: number | null,
/** キーワード */
keywords: ClassKeyword[],
/** 時限 */
periods: Period[],
/** 学部 */
faculty: string,
/** Moodle ID */
moodle_id: number,
/** 成績情報 */
grade_info: GradeInfo | null
}

/** 登録したクラス情報 */
export type MyClassInfo = {
/** クラス登録データベースのID !! DO NOT USE in Client !! */
id: number,
/** ユーザーID */
user?: string,
/** クラス情報 */
cls: ClassInfo,
/** クラステーマカラー */
color: string,
/** メモ */
memo?: string,
/** カスタマイズした科目名 */
c_subject?: string | null,
/** カスタマイズした講義題目 */
c_theme?: string | null,
/** カスタマイズしたMoodle ID */
c_moodle_id?: number | null,
/** カスタマイズした教員 */
c_teacher?: string | null,
/** カスタマイズした場所 */
c_place?: string | null,
/** カスタマイズした時限 */
c_periods?: Period[] | null
}

/** 絞り込みの名前 */
export type SearchKey =
"period" |
"year" |
"semester" |
"faculty" |
"keyword" |
"order_by"

/** 絞り込みのクエリ */
export type SearchQuery = {
[key in SearchKey]: string[]
}

/** 絞り込みオプション */
export type SearchFilter = {
/** 絞り込みの名前 */
name: SearchKey,
/** 絞り込みのラベル */
label: string,
/** 単選択か複選択か */
type: 'choice' | 'multiple_choice',
/** 選択肢 */
choices: [string | number, string | number][]
}

/** 並び替えオプション */
export type SortOrder = {
/** 並び替えの名前 */
name: string,
/** 並び替えのラベル */
label: string,
/** デフォルトで選択されているかどうか */
default: boolean
}

/** クラス情報の一覧 */
export type InfoList<T> = {
/** リンク */
links: {
    /** 次ページのリンク */
    next: string,
    /** 前ページのリンク */
    previous: string,
    /** ページなしリンク */
    pageless: string,
},
/** 合計ヒット数 */
count: number,
/** 合計ページ数 */
total_pages: number,
/** 現在のページ */
current_page: number,
/** クラス情報リスト */
results: T[]
}

/** ユーザー情報 */
export type UserInfo = {
/** ユーザーのメールアドレス */
email: string,
/** ユーザーアイコンのURL */
picture_url: string,
/** ユーザー名 */
username: string,
}

/** @deprecated かもしれない */
export type filterQuery = {
    /** クラス情報のID */
    id?: number,
    /** 開講年度 */
    year?: number,
    /** 開講期間 */
    semester?: string,
    /** 期間のインデックス */
    semester_int?: number,
    /** 時限 */
    period?: Period[]
}

/** Hupass BackendのURI */
const backend_uri = import.meta.env.VITE_BACKEND_URI ?? "https://localhost:8000";

/** 通信用AxiosInstance */
export const apiClient = axios.create({
    baseURL: backend_uri,
    headers: {
        'Content-Type': 'application/json;charset=utf-8;',
        'Access-Control-Allow-Origin': '*'
    },
    paramsSerializer: {
        indexes: null
    }
})

// TokenをHeaderのAuthorizationに含める
apiClient.interceptors.request.use(
    (config) => {
        const matches = document.cookie.match(/token=(\w*)/)
        if (matches) config.headers = Object.assign({ Authorization: 'Token ' + matches[1] }, config.headers)
        return config
    }
)

/** サーバーと通信するための関数 */
const HupassApiServer = {
    /**
     * IDからクラス情報を取得
     * @param id クラスのID
     * @returns クラス情報
     */
    GetClass: (id: number) => {
        return apiClient.get<ClassInfo>(`/classes/${id}/`)
    },

    /**
     * クラス一覧を検索
     * @param params 検索条件
     * @returns 検索条件に一致するクラスの一覧
     */
    ListClasses: (params?: {}) => {
        return apiClient.get<InfoList<ClassInfo>>('/classes/', { params: params })
    },

    /**
     * 
     * @param data 
     * @returns 
     */
    GetToken: (data: {}) => {
        return apiClient.post('/token/', data)
    },

    /**
     * クラスのIDから登録したクラスの情報を取得
     * @param id クラスのID
     * @returns 登録したクラスの情報
     */
    GetMyClass: (id: number) => {
        return apiClient.get<MyClassInfo>(`/myclass/${id}/`)
    },

    /**
     * クラスを登録
     * @param data 登録するクラスのIDと色
     * @returns ？
     */
    CreateMyClass: (data: { cls_id: number, color: string }) => {
        return apiClient.post('/myclass/', data)
    },

    /**
     * クラスの登録解除
     * @param id 登録解除するクラスのID
     * @returns ？
     */
    DeleteMyClass: (id: number) => {
        return apiClient.delete(`/myclass/${id}/`)
    },

    /**
     * 登録したクラスの一覧を取得
     * @returns 登録したクラスのリスト
     */
    ListMyClasses: () => {
        return apiClient.get<InfoList<MyClassInfo>>('/myclass/')
    },

    /**
     * フィルターのオプションを取得
     * @returns フィルターのオプションのリスト
     */
    GetFilterOptions: () => {
        return apiClient.get<SearchFilter[]>('/search-options/')
    },

    /**
     * 並び替えのオプションを取得
     * @returns 並び替えのオプションのリスト
     */
    GetSortOptions: () => {
        return apiClient.get<SortOrder[]>('/sort-options/')
    },

    ConvertToken: (data: {}) => {
        return apiClient.post('/token/', data)
    },

    GetUserInfo: (user: string) => {
        return apiClient.get<UserInfo>('/user/' + user + "/")
    },

    /**
     * 登録したクラスの情報を更新
     * @param data クラス情報
     * @returns ？
     */
    UpdateMyClass: (data: {
        cls_id: number,
        c_subject? : string,
        c_theme? : string,
        c_moodle_id?: number,
        c_teacher?: string,
        c_place?: string,
        c_periods?: Period[],
        memo?: string,
        color?: string,
    }) => {
        return apiClient.patch(`/myclass/`, data)
    },
}

export default HupassApiServer