import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverContentProps,
  PopoverHeader,
  PopoverTrigger,
  PositionProps,
  SystemStyleObject,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FcImport } from "react-icons/fc";
import {
  IoInformationCircleOutline,
  IoMoonOutline,
  IoNotificationsOutline,
  IoPersonOutline,
  IoSunnyOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import HupassApiServer, { UserInfo } from "src/app/HupassApiServer";
import { useGrayText, useLocalStorage } from "src/app/hooks";
import HupassLogo from "src/components/application/HupassLogo";

interface Notification {
  type: "info" | "alert";
  id: string;
  title?: string;
  body?: string;
}

/** HEADER */
export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [read, setRead] = useLocalStorage<string[]>("readNotficationIds", []);
  const [cookies, , removeCookie] = useCookies(["token", "user"]);
  const toast = useToast();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { toggleColorMode, colorMode } = useColorMode();
  const indicator: PositionProps & { _before: SystemStyleObject } = {
    pos: "relative",
    _before: {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bgColor: "orange.300",
      w: 3,
      h: 3,
      borderRadius: "full",
      border: "2px",
      borderColor: "chakra-body-bg",
    },
  };
  const grayText = useGrayText();

  const popoverContentProps: PopoverContentProps = {
    boxShadow: "2xl",
    borderRadius: "xl",
    border: "none",
  };

  useEffect(() => {
    document
      .querySelector("meta[name=theme-color]")
      ?.setAttribute("content", colorMode === "light" ? "#ffffff" : "#1a202c");
  }, [colorMode]);

  useEffect(() => {
    if (cookies.user)
      HupassApiServer.GetUserInfo(cookies.user)
        .then((res) => setUserInfo(res.data))
        .catch((e: AxiosError) => {
          console.error(e);
          toast({
            title: "ユーザー情報の取得に失敗しました。",
            status: "error",
            isClosable: true,
            description: (
              <>
                <Text>CODE: {e.code}</Text>
                <Text>STATUS: {e.response?.status}</Text>
                <Text>DATA: {e.response?.data?.toString()}</Text>
              </>
            ),
            duration: 9999,
          });
          removeCookie("token");
          removeCookie("user");
        });
    else setUserInfo(undefined);
  }, [cookies, removeCookie, toast]);

  useEffect(() => {
    fetch("/json/notfications.json").then(
      async (res) => setNotifications(await res.json()),
      console.error
    );
  }, []);

  return (
    <Box
      color={useColorModeValue("gray.600", "white")}
      borderBottom={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
      px={4}
      zIndex={2}
    >
      <Box px={0}>
        <Flex minH={"60px"} py={{ base: 2 }} align={"center"}>
          <Link to="/">
            <HupassLogo />
          </Link>
          <Flex flex={1} justify={"flex-end"} direction={"row"} gap={2}>
            {/** Settings */}
            <IconButton
              aria-label="DisplayMode"
              icon={useColorModeValue(<IoMoonOutline />, <IoSunnyOutline />)}
              isRound
              onClick={toggleColorMode}
            />
            {/** Notfication */}
            <Popover
              onClose={() =>
                setRead(notifications.map((notification) => notification.id))
              }
            >
              <PopoverTrigger>
                <IconButton
                  aria-label="Notice"
                  icon={<IoNotificationsOutline />}
                  isRound
                  {...(!notifications.every((notification) =>
                    read.includes(notification.id)
                  ) && { ...indicator, colorScheme: "orange" })}
                />
              </PopoverTrigger>
              <PopoverContent {...popoverContentProps}>
                <PopoverCloseButton />
                <PopoverHeader>お知らせ</PopoverHeader>
                <PopoverBody>
                  <VStack align={"stretch"} divider={<Divider />}>
                    {notifications.map((notification, i) => (
                      <Flex
                        key={notification.id + i}
                        gap={3}
                        rounded={"md"}
                        pos="relative"
                        {...(!read.includes(notification.id) && indicator)}
                      >
                        <Flex py={1}>
                          <Icon fontSize={"2xl"}>
                            <IoInformationCircleOutline />
                          </Icon>
                        </Flex>
                        <Box flex={1}>
                          <Text fontSize={"sm"}>{notification.title}</Text>
                          {notification.body?.split("\n").map((text, i) => (
                            <Text key={i} fontSize={"xs"} color={grayText}>
                              {text}
                              <br />
                            </Text>
                          ))}
                        </Box>
                      </Flex>
                    ))}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            {/** User Menu */}
            <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
              <PopoverTrigger>
                <IconButton
                  aria-label="User Menu"
                  icon={
                    userInfo ? (
                      <Avatar
                        name={userInfo.username}
                        src={userInfo.picture_url}
                        w="40px"
                        h="40px"
                      />
                    ) : (
                      <IoPersonOutline />
                    )
                  }
                  isRound
                />
              </PopoverTrigger>
              <PopoverContent {...popoverContentProps}>
                <PopoverCloseButton />
                <PopoverBody>
                  <Center>
                    <VStack py={2} gap={4}>
                      {userInfo ? (
                        <>
                          <Avatar size={"xl"} src={userInfo.picture_url} />
                          <Center flexDirection={"column"}>
                            <Heading>{userInfo.username}</Heading>
                            <Text>{userInfo.email}</Text>
                          </Center>
                          <Button
                            onClick={() => {
                              removeCookie("token");
                              removeCookie("user");
                            }}
                          >
                            ログアウト
                          </Button>
                        </>
                      ) : (
                        <>
                          <Icon fontSize={"8xl"} as={FcImport} />
                          <Heading>ログイン</Heading>
                          <Text>ログインしてデータを同期</Text>
                          <Button as={Link} to={"login"} onClick={onClose}>
                            ログイン
                          </Button>
                        </>
                      )}
                    </VStack>
                  </Center>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}
