import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import TuneIcon from '@mui/icons-material/Tune';
import { alpha, Button, ButtonGroup, Checkbox, Collapse, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, Stack, styled, Toolbar } from '@mui/material';
import { MutableRefObject, useRef, useState } from 'react';
import { useMatches } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import BottomRoundedDrawer from '../../components/BottomRoundedDrawer';
import { setQuery } from '../../features/search/searchSlice';
import { selectCalendar } from '../calendar/calendarSlice';

const Search = styled(Stack)(({ theme }) => ({
    borderRadius: '9999px',
    backgroundColor: alpha(theme.palette.getContrastText(theme.palette.background.default), 0.06),
    transition: theme.transitions.create(['background-color']),
    '&:hover': {
        backgroundColor: alpha(theme.palette.getContrastText(theme.palette.background.default), theme.palette.action.hoverOpacity),
    },

}));

export default function SearchHeader() {
    const searchQuery = useAppSelector((state) => state.search);
    const { filters, orders } = useAppSelector((state) => selectCalendar(state));
    const dispatch = useAppDispatch();
    const [reverse, setReverse] = useState(searchQuery.order_by.join().charAt(0) === "-");
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState(false);
    const taskId: MutableRefObject<NodeJS.Timeout | undefined> = useRef()
    const matches = useMatches();
    const searchMode = matches[1]?.id === "search"


    return (
        <>
            <Toolbar>
                <Search
                    direction="row"
                    sx={{ flexGrow: 1, width: '100%' }}
                    alignItems="center"
                >
                    <SearchIcon sx={{ mx: 1.5, my: 1 }} />
                    <InputBase
                        sx={{ color: "inherit" }}
                        placeholder='Search...'
                        defaultValue={searchQuery.keyword.join(" ")}
                        fullWidth
                        onChange={
                            e => {
                                clearTimeout(taskId.current)
                                taskId.current = setTimeout((e) => dispatch(setQuery({
                                    key: "keyword",
                                    value: e.target.value.split(/\s/)
                                })), 500, e)
                            }
                        } />
                </Search>
            </Toolbar>
            <Toolbar>
                <ButtonGroup
                    fullWidth
                    variant='text'
                >
                    <Button
                        startIcon={<SortIcon />}
                        onClick={() => setSort(true)}
                        fullWidth
                    >
                        {orders.map(({ name, label }) => name === searchQuery.order_by.join() ? label : null).join("")}
                    </Button>
                    <Button
                        startIcon={<TuneIcon />}
                        onClick={() => setFilter('filter')}
                        fullWidth
                    >
                        絞り込み
                    </Button>
                </ButtonGroup>
                <BottomRoundedDrawer
                    title='並び替え'
                    open={Boolean(sort)}
                    onClose={() => setSort(false)}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="label" htmlFor={"order_by_reverse"}>
                                <ListItemIcon>
                                    <Checkbox
                                        name={"order_by_reverse"}
                                        id={"order_by_reverse"}
                                        checked={reverse}
                                        onChange={(e) => {
                                            setReverse(e.target.checked)
                                            dispatch(setQuery({
                                                key: "order_by",
                                                value: searchQuery["order_by"].map(o => (e.target.checked ? '-' + o : '' + o.substring(1)))
                                            }))
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={"逆順"} />
                            </ListItemButton>
                        </ListItem>
                        {orders.filter((opt) => reverse ? opt.name.charAt(0) === "-" : opt.name.charAt(0) !== "-").map((opt, i) => (
                            <ListItem disablePadding key={i}>
                                <ListItemButton component="label" htmlFor={opt.name}>
                                    <ListItemIcon>
                                        <Radio
                                            name={"order_by"}
                                            id={opt.name}
                                            checked={searchQuery["order_by"].includes(opt.name)}
                                            onChange={(e) => e.target.checked ? dispatch(setQuery({
                                                key: "order_by",
                                                value: [opt.name]
                                            })) : null}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={opt.label.match(/[^()]*/)} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </BottomRoundedDrawer>
                <BottomRoundedDrawer
                    title='絞り込み'
                    open={Boolean(filter)}
                    onClose={() => setFilter('')}
                >
                    <List>
                        {filters.map(({ name, label, type, choices }, i) => (
                            <ListItem
                                disablePadding
                                key={i}
                            //sx={{ borderTop: '1px solid ' + theme.palette.divider }}
                            >
                                <ListItemButton onClick={() => setFilter(name)}>
                                    <ListItemText
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                        primary={label}
                                        primaryTypographyProps={{
                                            flexShrink: 0,
                                            pr: 1
                                        }}
                                        secondary={searchQuery[name].map(value => choices.map(([key, label]) => key.toString() === value ? label : '').join("")).join(", ")}
                                        secondaryTypographyProps={{
                                            sx: {
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }
                                        }} />
                                    <ListItemIcon sx={{ minWidth: '2em', justifyContent: 'flex-end' }}>
                                        <ChevronRightIcon />
                                    </ListItemIcon>
                                </ListItemButton>
                                <BottomRoundedDrawer
                                    title={label}
                                    open={filter === name}
                                    onClose={() => setFilter('filter')}
                                >
                                    <List>
                                        {choices.map((opt, i) => (
                                            <ListItem disablePadding key={i}>
                                                <ListItemButton component="label" htmlFor={name + "_" + opt[0]}>
                                                    <ListItemIcon>
                                                        {type === "choice" ?
                                                            <Radio
                                                                name={name}
                                                                id={name + "_" + opt[0]}
                                                                checked={searchQuery[name].includes(opt[0].toString())}
                                                                value={opt[0]}
                                                                onChange={(e) => e.target.checked ? dispatch(setQuery({
                                                                    key: name,
                                                                    value: [opt[0].toString()]
                                                                })) : null}
                                                            /> :
                                                            <Checkbox
                                                                name={name}
                                                                id={name + "_" + opt[0]}
                                                                checked={searchQuery[name].includes(opt[0].toString())}
                                                                value={opt[0]}
                                                                onChange={(e) => dispatch(setQuery({
                                                                    key: name,
                                                                    value: e.target.checked ?
                                                                        searchQuery[name].concat([opt[0].toString()]) :
                                                                        searchQuery[name].concat().filter(item => item !== opt[0])
                                                                }))}
                                                            />
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText primary={opt[1]} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </BottomRoundedDrawer>
                            </ListItem>
                        ))}
                    </List>
                </BottomRoundedDrawer>
            </Toolbar>
        </ >
    )
}