import { Card, CardContent, CardHeader, Container, IconButton, Pagination, styled, Toolbar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import HupassApiServer from "../../app/HupassApiServer";
import SearchClassCard from "./SearchClassCard";
import SearchHeader from "./SearchHeader";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const NowrapPagination = styled(Pagination)(() => ({
    alignSelf: "center",
    "& .MuiPagination-ul": {
        flexWrap: "nowrap"
    }
}))

export default function Search() {
    const searchQuery = useAppSelector((state) => state.search);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [count, setCount] = useState(0)
    const [results, setResults] = useState(Array(3).fill(null))
    const taskId = useRef<NodeJS.Timeout>()
    const dispatch = useAppDispatch();
    const pagination = <NowrapPagination count={totalPages} page={currentPage} onChange={(e, page) => setCurrentPage(page)} />
    const navigate = useNavigate()

    useEffect(() => {
        setCurrentPage(1)
    }, [searchQuery])

    useEffect(() => {
        clearTimeout(taskId.current);
        taskId.current = setTimeout(() => {
            setResults(Array(3).fill(null));
            HupassApiServer.ListClasses(
                { ...searchQuery, page: currentPage })
                .then(value => value.data)
                .then(data => {
                    setResults(data.results);
                    setTotalPages(data.total_pages);
                    setCount(data.count)
                    taskId.current = undefined
                })
                .catch(err => {
                    console.error(err)
                    setResults([])
                    setTotalPages(1)
                    setCount(0)
                })

        }, taskId.current ? 500 : 0)
    }, [searchQuery, currentPage, dispatch])

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, [currentPage])

    return (
        <Container sx={{ my: 2 }}>
            <Toolbar>
                <IconButton
                    edge='start'
                    onClick={() => navigate(-1)}
                >
                    <IoArrowBack />
                </IconButton>
            </Toolbar>
            <SearchHeader />
            <Stack spacing={2}>
                <Stack>
                    <Typography variant="h6" color="text.primary">検索結果</Typography>
                    <Typography variant="body2" color="text.secondary">{count}件中 {currentPage * 30 - 29}-{Math.min(currentPage * 30, count)}件目を表示</Typography>
                </Stack>
                {pagination}
                {results.length ? results.map((classinfo, i) => (
                    <SearchClassCard classinfo={classinfo} key={i} />
                )) :
                    <Card>
                        <CardHeader
                            title="結果なし"
                        />
                        <CardContent sx={{ pt: 0 }}>
                            <Typography variant="body2" color="text.secondary">条件を変えて再度検索してください。</Typography>
                        </CardContent>
                    </Card>
                }
                {pagination}
            </Stack>
        </Container>
    )
}