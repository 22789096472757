import CssBaseline from "@mui/material/CssBaseline";
import { amber, blue } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PropsWithChildren, useMemo } from "react";
import { useAppSelector } from "src/app/hooks";
import { selectSettings } from "src/features/settings/settingsSlice";

export default (/** @deprecated */ function MuiProvider({
  children,
}: PropsWithChildren) {
  const { isDarkMode } = useAppSelector((state) => selectSettings(state));

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkMode ? "dark" : "light",
          primary: {
            main: blue[isDarkMode ? 200 : 700],
          },
          secondary: {
            main: amber[isDarkMode ? 200 : 700],
          },
        },
        shape: {
          borderRadius: 6,
        },
        spacing: 8,
        typography: {
          fontFamily:
            "Roboto, 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Noto Sans JP', sans-serif",
        },
        components: {
          MuiToggleButton: {
            styleOverrides: {
              root: {
                overflow: "hidden",
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                overscrollBehavior: "contain",
              },
            },
          },
          MuiBackdrop: {
            styleOverrides: {
              root: {
                overscrollBehavior: "contain",
              },
            },
          },
        },
      }),
    [isDarkMode]
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
});
