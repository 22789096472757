import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ToggleButtonSetting from "../base/ToggleButtonSetting";
import { selectSettings, setFontSize } from "../settingsSlice";

export default function FontSize() {
    const { fontSize } = useAppSelector(state => selectSettings(state))
    const dispatch = useAppDispatch()
    const handleChange = (e: React.MouseEvent, value: typeof fontSize) => dispatch(setFontSize(value ?? fontSize))

    return (
        <ToggleButtonSetting
            title='フォントサイズ'
            value={fontSize}
            onChange={handleChange}
            options={[{
                label: '小',
                value: 'sm',
            },{
                label: '中',
                value: 'md',
            },{
                label: '大',
                value: 'lg',
            }]}
        />
    )
}