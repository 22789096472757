import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Linkify from "linkify-react";
import { useRef, useState } from "react";
import { ColorIndicatorWidth } from "../../app/HupassApiClient";
import { ClassInfo } from "../../app/HupassApiServer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCalendar, updateClass } from "../calendar/calendarSlice";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

type Props = {
  cls?: ClassInfo;
};

export default function DetailMemo({ cls }: Props) {
  const dispatch = useAppDispatch();
  const { classes } = useAppSelector((state) => selectCalendar(state));
  const mycls = classes[cls?.id ?? 0];
  const [editing, setEditing] = useState(0);
  const [memo, setMemo] = useState(mycls?.memo);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  if (!mycls) return <></>;
  if (memo === undefined && mycls.memo !== undefined) setMemo(mycls.memo);

  return (
    <Box>
      <Stack direction={"row"}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            my: 1,
            px: 2.5,
          }}
        >
          講義メモ
        </Typography>
        <IconButton
          onClick={() => setEditing(1 - editing)}
          sx={{ ml: "auto", mr: 1 }}
        >
          {editing ? <CheckIcon /> : <EditIcon />}
        </IconButton>
      </Stack>
      <Card
        sx={{
          position: "relative",
          pl: ColorIndicatorWidth,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            width: ColorIndicatorWidth,
            height: "100%",
            backgroundColor: mycls.color,
          }}
        />
        <CardContent>
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                py: "4px",
                lineHeight: "1.4375em",
                opacity: 1 - editing,
                position: "absolute",
                top: 0,
              }}
            >
              <Linkify
                options={{
                  render: ({ attributes, content }) => (
                    <Link
                      {...attributes}
                      sx={{ position: "relative", zIndex: 1 }}
                    >
                      {content}
                    </Link>
                  ),
                }}
              >
                {memo?.split("\n").flatMap((text, i, original) => [
                  <Typography
                    component={"span"}
                    key={i}
                    sx={{ lineHeight: "inherit" }}
                  >
                    {text}
                  </Typography>,
                  <br key={i + original.length} />,
                ])}
              </Linkify>
            </Box>
            <TextField
              multiline
              fullWidth
              value={memo}
              onFocus={() => {
                setEditing(1);
              }}
              onChange={(e) => {
                setMemo(e.target.value);
              }}
              onBlur={(e) => {
                setEditing(0);
                dispatch(updateClass({ ...mycls, memo: e.target.value }));
              }}
              variant="standard"
              sx={{ opacity: editing }}
              inputRef={inputRef}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
