import { Box, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, Typography, TypographyProps } from "@mui/material";

type Props<T> = {
    title: string,
    titleProps?: TypographyProps,
    toggleButtonGroupProps?: ToggleButtonGroupProps,
    onChange?: (event: React.MouseEvent, value: T) => void,
    value?: T,
    options?: {
        label?: string,
        value: T
    }[]
    children?: React.ReactNode
}

export default function ToggleButtonSetting<T extends string>({ title, titleProps, toggleButtonGroupProps, onChange, value, options, children }: Props<T>) {

    return (
        <Box>
            <Typography
                gutterBottom
                color='text.secondary'
                variant='body2'
                {...titleProps}
            >
                {title}
            </Typography>
            <ToggleButtonGroup
                value={value}
                onChange={onChange}
                exclusive
                color="primary"
                fullWidth
                {...toggleButtonGroupProps}
            >
                {options?.map(
                    (props, i) => (
                        <ToggleButton
                            key={i}
                            value={props.value}
                        >
                            {props.label ?? props.value}
                        </ToggleButton>
                    )
                )}
                {children}
            </ToggleButtonGroup>
        </Box>
    )
}