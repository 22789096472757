import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import PaletteIcon from '@mui/icons-material/Palette';
import PlaceIcon from '@mui/icons-material/Place';
import SchoolIcon from '@mui/icons-material/School';
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, FormControl, IconButton, InputLabel, List, ListItem, MenuItem, Select, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ColorIndicatorWidth, FlatColors, PeriodToStr, StrToPeriod } from '../../app/HupassApiClient';
import { ClassInfo } from "../../app/HupassApiServer";
import BottomRoundedDrawer from '../../components/BottomRoundedDrawer';
import { registerClass, selectCalendar, unRegisterClass, updateClass } from "../calendar/calendarSlice";

type Props = {
    cls?: ClassInfo
}

export default function DetailClassInformation({ cls }: Props) {
    const { classes } = useAppSelector(state => selectCalendar(state))
    const mycls = classes[cls?.id ?? 0]
    const dispatch = useAppDispatch()
    const [edit, setEdit]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
    
    const listProperty: {
        value: React.ReactNode | undefined,
        icon: JSX.Element
    }[] = [{
        value: mycls?.c_teacher ?? (cls ? cls.teacher : <Skeleton />),
        icon: <SchoolIcon />
    }, {
        value: mycls?.c_place ?? (cls ? cls.place : <Skeleton />),
        icon: <PlaceIcon />
    }, {
        value: (mycls?.c_periods?.length ? mycls?.c_periods : cls?.periods ?? []).map(period => PeriodToStr(period)).join(', ') || <Skeleton />,
        icon: <EventIcon />
    },]

    return (
        <Box>
            <Typography
                variant='body2'
                color='text.secondary'
                sx={{
                    my: 1, px: 2.5
                }}
            >
                講義情報
            </Typography>
            <Card
                sx={{
                    position: 'relative',
                    pl: ColorIndicatorWidth,
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: ColorIndicatorWidth,
                        height: "100%",
                        bgcolor: mycls?.color ?? 'primary.main',
                    }} />
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: theme => theme.spacing(1),
                        top: theme => theme.spacing(1),
                        display: mycls ? 'flex' : 'none',
                    }}
                    color='default'
                    onClick={() => setEdit(!edit)}
                >
                    <EditIcon />
                </IconButton>
                <CardContent
                    sx={{
                        pb: 0
                    }}
                >
                    <Typography
                        variant="body2"
                        color='text.secondary'
                    >
                        {cls ? `${cls.year}年度 ${cls.semester}` : <Skeleton />}
                    </Typography>
                </CardContent>
                <CardHeader
                    title={cls ? mycls?.c_subject ?? cls.subject : <Skeleton />}
                    subheader={cls ? mycls?.c_theme ?? cls.theme : <Skeleton />}
                />
                <CardContent
                    sx={{
                        pt: 0,
                        overflow: 'hidden',
                    }}
                >
                    <Stack
                        spacing={1.5}
                    >
                        <Typography
                            variant='body2'
                            color='text.secondary'
                        >
                            {cls?.credit} 単位
                        </Typography>
                        <Stack>
                            {listProperty.map((item, i) => (
                                <Stack
                                    key={i}
                                    direction='row'
                                    color='text.secondary'
                                    alignItems='center'
                                >
                                    <Box sx={{ py: 1, pr: 2, display: 'inline-flex' }}>
                                        {item.icon}
                                    </Box>
                                    <Typography sx={{ flexGrow: '1' }}>
                                        {item.value}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                </CardContent>
                <CardActions
                    sx={{
                        justifyContent: "space-between"
                    }}
                >
                    <ButtonGroup
                        variant='outlined'
                    >
                        <Button
                            //endIcon={<ChevronRightRoundedIcon />}
                            href={`http://syllabus01.academic.hokudai.ac.jp/Syllabi/Public/Syllabus/SylDetail.aspx?lct_year=${cls?.year}&lct_cd=${('000000' + cls?.cls_id?.toString()).slice(-6)}&langMode=1`} //'https://moodle.elms.hokudai.ac.jp/course/view.php?id=112180'
                            disabled={cls?.cls_id ? false : true}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            シラバス
                        </Button>
                        <Button
                            //endIcon={<ChevronRightRoundedIcon />}
                            href={`https://moodle.elms.hokudai.ac.jp/course/view.php?id=${mycls?.c_moodle_id ?? cls?.moodle_id}`} //'https://moodle.elms.hokudai.ac.jp/course/view.php?id=112180'
                            disabled={(mycls?.c_moodle_id ?? cls?.moodle_id) ? false : true}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Moodle
                        </Button>
                        <Button
                            //endIcon={<ChevronRightRoundedIcon />}
                            href={`https://www.elms.hokudai.ac.jp/group/grouppage?idnumber=p${cls?.year.toString().slice(-2) + ('000000' + cls?.cls_id?.toString()).slice(-6)}`} //'https://www.elms.hokudai.ac.jp/group/grouppage?idnumber=p22015607'
                            disabled={cls?.cls_id ? false : true}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ELMS
                        </Button>
                    </ButtonGroup>
                    <Button
                        variant='contained'
                        disableElevation
                        onClick={() => cls ? mycls ? dispatch(unRegisterClass(mycls.cls)) : registerClass(cls).then(appthunk => dispatch(appthunk)) : null}
                        color={mycls ? 'warning' : 'primary'}
                        disabled={!Boolean(cls)}
                    >
                        {cls ? mycls ? "削除" : "追加" : <Skeleton />}
                    </Button>
                </CardActions>
            </Card>
            <BottomRoundedDrawer
                title='講義情報の編集'
                open={edit}
                onClose={() => setEdit(false)}
            >
                <List>
                    <ListItem>
                        <TextField
                            id='edit_subject'
                            label='科目名'
                            fullWidth
                            variant='filled'
                            defaultValue={mycls?.c_subject ?? cls?.subject}
                            onBlur={e => mycls ? dispatch(updateClass({ ...mycls, c_subject: e.target.value })) : undefined}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            label='講義題目'
                            fullWidth
                            variant='filled'
                            defaultValue={mycls?.c_theme ?? cls?.theme}
                            onBlur={e => mycls ? dispatch(updateClass({ ...mycls, c_theme: e.target.value })) : undefined}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            label='担当教員'
                            fullWidth
                            variant='filled'
                            defaultValue={mycls?.c_teacher ?? cls?.teacher}
                            onBlur={e => mycls ? dispatch(updateClass({ ...mycls, c_teacher: e.target.value })) : undefined}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            label='講義室'
                            fullWidth
                            variant='filled'
                            defaultValue={mycls?.c_place ?? cls?.place}
                            onBlur={e => mycls ? dispatch(updateClass({ ...mycls, c_place: e.target.value })) : undefined}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            label='時限'
                            fullWidth
                            variant='filled'
                            defaultValue={(mycls?.c_periods?.length ? mycls?.c_periods : cls?.periods)?.map(period => PeriodToStr(period)).join(', ')}
                            onBlur={e => mycls ? dispatch(updateClass({ ...mycls, c_periods: e.target.value.split(/\s*,\s/).map(str => StrToPeriod(str)) })) : undefined}
                        />
                    </ListItem>
                    <ListItem>
                        <FormControl
                            variant='filled'
                            fullWidth
                        >
                            <InputLabel
                                id='class-color-label'
                            >
                                カラー
                            </InputLabel>
                            <Select
                                labelId='class-color-label'
                                label='Color'
                                MenuProps={{
                                    sx: {
                                        maxHeight: '50vh'
                                    }
                                }}
                                onChange={
                                    e => mycls && dispatch(updateClass({ ...mycls, color: String(e.target.value) }))
                                }
                                value={mycls?.color ?? ''}
                            >
                                {Object.entries(FlatColors).map(
                                    ([name, color]) => (
                                        <MenuItem
                                            key={name}
                                            value={color}
                                        >
                                            <Stack
                                                direction='row'
                                                gap={1}
                                                alignItems='center'
                                            >
                                                <PaletteIcon sx={{ color }} />
                                                <Typography>{name.split('').map((str, i) => i ? str : str.toUpperCase())}</Typography>
                                            </Stack>
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </ListItem>
                </List>
            </BottomRoundedDrawer>
        </Box >
    )
}