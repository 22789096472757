import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  existsToken,
  FlatColors,
  NotSimilarColorRadius,
  PeriodToStr,
  StrToSemester,
  ThisSemester,
  ThisYear,
  Today,
} from "../../app/HupassApiClient";
import HupassApiServer from "../../app/HupassApiServer";
import {
  ClassInfo,
  MyClassInfo,
  Period,
  SearchFilter,
  SortOrder,
} from "../../app/HupassApiServer";
import { AppThunk, RootState } from "../../app/store";

const localstorageKey = "calendar";
export const saveState = (state: CalendarState) =>
  localStorage.setItem(localstorageKey, JSON.stringify(state));
export const localState: CalendarState | null = JSON.parse(
  localStorage.getItem(localstorageKey) ?? "null"
);
localState && (localState.mode = existsToken() ? "online" : "guest");

export type CalendarState = {
  /**
   * 動作モード
   * online - ログインしサーバーサイドと通信して情報交換する
   * offline - ログインしているがサーバーと通信せず
   * guest - ログインせずローカルのみで動作
   * @deprecated tokenでいい
   */
  mode: "online" | "offline" | "guest";
  /** エラーに関する情報 */
  error: {
    show: boolean;
    title: string;
    contents: string[];
  };
  /** 時限の配列 */
  periods: string[][];
  /** 曜日の配列 */
  days: string[];
  /** 登録した授業情報一覧 */
  classes: {
    [id: number]: MyClassInfo | undefined;
  };
  /** 時間割のid配列 */
  timetable: {
    [year: number]: {
      [semester: number]: number[][];
    };
  };
  /** 表示する年度 */
  year: number;
  /** 表示する学期 */
  semester: number;
  /** 週表示か */
  weeklyView: boolean;
  /** 表示する曜日 */
  day: number;
  /** 検索フィルタ一覧 */
  filters: SearchFilter[];
  /** 並び替え順一覧 */
  orders: SortOrder[];
};

const initialState: CalendarState = {
  mode: existsToken() ? "online" : "guest",
  error: {
    show: false,
    title: "",
    contents: [],
  },
  periods: [
    ["8:45", "10:15"],
    ["10:30", "12:00"],
    ["13:00", "14:30"],
    ["14:45", "16:15"],
    ["16:30", "18:00"],
  ],
  days: ["月", "火", "水", "木", "金"],
  classes: {},
  timetable: {},
  year: ThisYear,
  weeklyView: true,
  day: 0,
  semester: ThisSemester,
  filters: [],
  orders: [],
  ...localState,
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<CalendarState["mode"]>) => {
      state.mode = action.payload;
      saveState(state);
    },
    setClass: (state, action: PayloadAction<MyClassInfo>) => {
      // timetableに登録
      const { classes, days, periods } = state;
      const {
        c_periods,
        c_teacher,
        c_theme,
        c_place,
        c_subject,
        c_moodle_id,
        memo,
        cls,
        color,
      } = action.payload;
      const { id, year, semester: semesterStr, periods: period } = cls;
      /** 旧授業情報 */
      const oldMycls: MyClassInfo | undefined = classes[id];
      /** 旧時限 */
      const oldPeriod = oldMycls
        ? oldMycls.c_periods?.length
          ? oldMycls.c_periods
          : oldMycls.cls.periods
        : [];
      /** 新時限 */
      const newPeriod = c_periods?.length ? c_periods : period;
      // 削除処理
      oldPeriod.forEach((op) =>
        StrToSemester(semesterStr).forEach((semester) => {
          if (
            !newPeriod.some(
              (np) => np.day === op.day && np.period === op.period
            )
          ) {
            const { day, period: nth } = op;
            // 登録解除
            state.timetable[year][semester][day][nth] = 0;
          }
        })
      );
      // 登録処理
      let overwrapping: Period[] = [];
      let invalid: Period[] = [];
      let success: [number, number, number][] = [];
      newPeriod.forEach((np) =>
        StrToSemester(semesterStr).forEach((semester) => {
          // 旧時間に存在しない時
          if (
            !oldPeriod.some(
              (op) => np.day === op.day && np.period === op.period
            )
          ) {
            const { day, period: nth } = np;
            // year, semesterが存在しない時に初期化
            if (!state.timetable[year]) state.timetable[year] = {};
            if (!state.timetable[year][semester])
              state.timetable[year][semester] = days.map(() =>
                periods.map(() => 0)
              );
            // 授業の上書きが起こった時
            if (day < 0 || nth < 0) {
              console.error("無効な時限です。", np);
              invalid.push(np);
            } else if (
              state.timetable[year][semester][day - 1][nth - 1] !== 0
            ) {
              console.error("時間割がすでに登録されています。", np);
              overwrapping.push(np);
            } else {
              state.timetable[year][semester][day - 1][nth - 1] = id;
              success.push([semester, day, nth]);
            }
          }
        })
      );
      if (invalid.length) {
        // 無効な時間割が存在した時
        // 変更を取り消し
        success.forEach(
          ([semester, day, nth]) =>
            (state.timetable[year][semester][day - 1][nth - 1] = 0)
        );
        // errorに内容登録
        state.error = {
          show: true,
          title: "無効な時限が指定されました",
          contents: overwrapping.map(
            (ovwPeriod) => ovwPeriod + "は無効な時限です。"
          ),
        };
      } else if (overwrapping.length) {
        // 上書きが発生した時
        // 変更を取り消し
        success.forEach(
          ([semester, day, nth]) =>
            (state.timetable[year][semester][day - 1][nth - 1] = 0)
        );
        // errorに内容登録
        state.error = {
          show: true,
          title: "登録する時間が重複しています",
          contents: overwrapping.map(
            (ovwPeriod) => PeriodToStr(ovwPeriod) + "が重複しています。"
          ),
        };
      } else {
        // APIと通信
        if (state.mode === "online") {
          if (oldMycls === undefined)
            HupassApiServer.CreateMyClass({
              cls_id: action.payload.cls.id,
              color: action.payload.color,
            }).catch((e) => console.error(e));
          else
            HupassApiServer.UpdateMyClass({
              cls_id: id,
              memo,
              ...(c_teacher !== undefined &&
                c_teacher !== null && {
                  c_teacher,
                }),
              ...(c_place !== undefined &&
                c_place !== null && {
                  c_place,
                }),
              ...(c_subject !== undefined &&
                c_subject !== null && {
                  c_subject,
                }),
              ...(c_theme !== undefined &&
                c_theme !== null && {
                  c_theme,
                }),
              ...(c_periods !== undefined &&
                c_periods !== null && {
                  c_periods,
                }),
              ...(c_moodle_id !== undefined &&
                c_moodle_id !== null && {
                  c_moodle_id,
                }),
              ...(color !== undefined &&
                color !== null && {
                  color,
                }),
            });
        }
        // classesに登録
        state.classes[action.payload.cls.id] = action.payload;
      }
      saveState(state);
    },
    deleteClass: (state, action: PayloadAction<number>) => {
      const { classes } = state;
      const mycls = classes[action.payload];
      // すでに削除されている時
      if (!mycls) return;
      /** 授業情報 */
      const {
        c_periods,
        cls: { year, semester, periods: period },
      } = mycls;
      // timetableから削除
      /** 旧時限 */
      const oldPeriod = c_periods?.length ? c_periods : period;
      // 削除処理
      oldPeriod.forEach((op) =>
        StrToSemester(semester).forEach((sem) => {
          const { day, period: nth } = op;
          state.timetable[year][sem][day - 1][nth - 1] = 0;
        })
      );
      // classesから削除
      delete state.classes[action.payload];
      if (state.mode === "online")
        HupassApiServer.DeleteMyClass(action.payload);
      saveState(state);
    },
    setClasses: (state, action: PayloadAction<MyClassInfo[]>) => {
      state.timetable = {};
      action.payload.forEach((mycls) => {
        /** 新時限 */
        const { c_periods, cls } = mycls;
        const { year, periods, id } = cls;
        const newPeriod = c_periods?.length ? c_periods : periods;
        state.classes[id] = mycls;
        newPeriod.forEach((np) =>
          StrToSemester(mycls.cls.semester).forEach((semester) => {
            const { day, period: nth } = np;
            // year, semesterが存在しない時に初期化
            if (!state.timetable[year]) state.timetable[year] = {};
            if (!state.timetable[year][semester])
              state.timetable[year][semester] = state.days.map(() =>
                state.periods.map(() => 0)
              );
            // 授業の上書きが起こった時
            if (day < 0 || nth < 0) {
              console.error("無効な時限です。", np);
              //invalid.push(np)
            } else if (
              state.timetable[year][semester][day - 1][nth - 1] !== 0
            ) {
              console.error("時間割がすでに登録されています。", np);
              //overwrapping.push(np)
            } else {
              state.timetable[year][semester][day - 1][nth - 1] = id;
              //success.push([semester, day, nth])
            }
          })
        );
      });
      saveState(state);
    },
    setYear: (state, action: PayloadAction<CalendarState["year"]>) => {
      state.year = action.payload;
      saveState(state);
    },
    setSemester: (state, action: PayloadAction<CalendarState["semester"]>) => {
      state.semester = action.payload;
      saveState(state);
    },
    setWeeklyView: (
      state,
      action: PayloadAction<CalendarState["weeklyView"]>
    ) => {
      state.weeklyView = action.payload;
      saveState(state);
    },
    setDay: (state, action: PayloadAction<CalendarState["day"]>) => {
      state.day = action.payload;
      saveState(state);
    },
    setError: (state, action: PayloadAction<CalendarState["error"]>) => {
      state.error = action.payload;
      saveState(state);
    },
    setFilters: (state, action: PayloadAction<CalendarState["filters"]>) => {
      state.filters = action.payload;
      saveState(state);
    },
    setOrders: (state, action: PayloadAction<CalendarState["orders"]>) => {
      state.orders = action.payload;
      saveState(state);
    },
  },
});

export const {
  setMode,
  setClass,
  deleteClass,
  setClasses,
  setError,
  setYear,
  setSemester,
  setWeeklyView,
  setDay,
  setFilters,
  setOrders,
} = calendarSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
/** Calendarのstateを選択 */
export const selectCalendar = (state: RootState) => state.calendar;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/**
 * クラスを登録
 * @param cls クラス情報
 * @returns AppThunk
 */
export const registerClass = (cls: ClassInfo): Promise<AppThunk> =>
  new Promise<AppThunk>((resolve) => {
    resolve((dispatch, getState) => {
      const { classes, timetable } = selectCalendar(getState());
      const { id, year, semester: semesterStr, periods: period } = cls;

      /** 使用済みの色 */
      let usedColors: string[] = [];
      /** 近くの時間割の色 */
      let nearColors: string[] = [];

      StrToSemester(semesterStr).forEach((semester) =>
        timetable[year]?.[semester]?.forEach((week, existingDay) =>
          week.forEach((id, existingNth) =>
            period.forEach((period) => {
              if (id === 0) return;
              const { day: newDay, period: newNth } = period;
              // 上下左右の時間割の色をnearcolorsに追加
              if (
                (existingDay - newDay) ** 2 + (existingNth - newNth) ** 2 <=
                  NotSimilarColorRadius ** 2 &&
                !nearColors.includes(classes[id]?.color ?? "")
              )
                nearColors.push(classes[id]?.color ?? "");
            })
          )
        )
      );
      /** 識別可能かつ使われてない色 */
      const possibleColors = Object.values(FlatColors).filter(
        (color) => !usedColors.includes(color)
      );
      const color =
        possibleColors[Math.floor(Math.random() * possibleColors.length)] ??
        Object.values(FlatColors)[
          Math.floor(Math.random() * Object.values(FlatColors).length)
        ];

      dispatch(setClass({ id, cls, color }));
    });
  });

export const unRegisterClass =
  (cls: ClassInfo): AppThunk =>
  (dispatch, getState) => {
    dispatch(deleteClass(cls.id));
  };

export const updateClass =
  (mycls: MyClassInfo): AppThunk =>
  (dispatch, getState) => {
    dispatch(setClass(mycls));
  };

export default calendarSlice.reducer;
