import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectCalendar, setSemester } from "../../../features/calendar/calendarSlice";
import ToggleButtonSetting from "../base/ToggleButtonSetting";

export default function SwitchSemester() {
    const { semester } = useAppSelector(state => selectCalendar(state))
    const dispatch = useAppDispatch()
    const handleChange = (e: React.MouseEvent, value: string) => dispatch(setSemester(Number(value) || semester))

    return (
        <ToggleButtonSetting
            title='学期選択'
            value={semester.toString()}
            onChange={handleChange}
            options={[{
                label: '春',
                value: '1',
            },{
                label: '夏',
                value: '2',
            },{
                label: '秋',
                value: '3',
            },{
                label: '冬',
                value: '4',
            },]}
        />
    )
}